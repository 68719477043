import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { LoaderFunction, useParams } from "react-router-dom";
import { MaterialRequestForm } from "../../@types/services";
import useAuth from "../../hooks/useAuth";
import {
  getMaterialRequestForm,
  uploadMaterialRequestFormAttach,
} from "../../services/materialRequestForms";
import NotFound from "../notFound";

export const loader: LoaderFunction = async ({
  params,
}): Promise<MaterialRequestForm> => {
  const materialRequestForm = await getMaterialRequestForm(
    params.materialRequestFormId || "",
  );
  return materialRequestForm;
};

interface MassOffer extends DialogProps {
  materialRequestFormId: string;
}

const MassOfferDialog: FC<MassOffer> = ({
  materialRequestFormId,
  open,
  onClose,
}): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [attach, setAttach] = useState<File | undefined>();
  const { enqueueSnackbar } = useSnackbar();

  async function updateAttach(): Promise<void> {
    setLoading(() => true);

    if (!attach) {
      return;
    }

    const formData = new FormData();
    formData.append("attachment", attach);

    uploadMaterialRequestFormAttach(materialRequestFormId, formData)
      .then(() => {
        enqueueSnackbar("Arquivo enviado com sucesso!");
        if (onClose) {
          onClose({}, "backdropClick");
        }
      })
      .catch(() => {
        enqueueSnackbar("Não foi possível enviar o arquivo!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Mass Offer</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogContentText>
            Baixe o template, preencha e envie
          </DialogContentText>
          <Button
            href={`/vbuyer/api/v2/material_request_forms/${materialRequestFormId}/mass_offer_template`}
            target="_blank"
          >
            Baixar template
          </Button>
        </div>

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "5px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            accept="xml"
            type="file"
            onChange={(e) => setAttach(() => e.target.files?.[0])}
            style={{
              border: "1px solid black",
              borderRadius: "2px",
              padding: "5px",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose({}, "backdropClick")}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          loadingPosition="center"
          onClick={() => updateAttach()}
          disabled={!attach}
        >
          Enviar arquivo
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ShowMaterialRequestForm: FC = (): ReactElement => {
  // const materialRequestForm = useLoaderData() as MaterialRequestForm; # TODO: Future version of React Router Dom (RouterProvier)
  const { materialRequestFormId } = useParams();
  const [materialRequestForm, setMaterialRequestForm] =
    useState<MaterialRequestForm>();
  const [notFound, setNotFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingGeneratePurchaseOrder, setLoadingGeneratePurchaseOrder] =
    useState(false);
  const { user } = useAuth();

  const handleFetchMaterialRequestForm = useCallback(async (id: string) => {
    setLoading(() => true);
    await getMaterialRequestForm(id)
      .then((resource) => {
        setMaterialRequestForm(() => resource);
      })
      .catch(() => {
        setNotFound(() => true);
      })
      .finally(() => {
        setLoading(() => false);
      });
  }, []);

  const handleClickOpen = (): void => {
    setOpen(() => true);
  };

  const handleClose = (): void => {
    if (materialRequestFormId) {
      handleFetchMaterialRequestForm(materialRequestFormId);
    }
    setOpen(() => false);
  };

  useEffect(() => {
    if (!materialRequestFormId) {
      setNotFound(() => true);
      return;
    }

    handleFetchMaterialRequestForm(materialRequestFormId);
  }, [handleFetchMaterialRequestForm, materialRequestFormId]);

  if (notFound || !materialRequestFormId) {
    return <NotFound />;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: 2,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <h1>{i18next.t("materialRequestForm.title")}</h1>

      {!materialRequestForm?.filled && (
        <Alert severity="info" sx={{ marginBottom: "15px" }}>
          {i18next.t("materialRequestForm.pendingFilling")}
        </Alert>
      )}

      {materialRequestForm?.purchase_order &&
        materialRequestForm.purchase_order.purchase_order_pdf_document
          ?.attachment && (
          <Button
            variant="outlined"
            color="success"
            href={`/vbuyer/purchase_orders/${materialRequestForm.purchase_order.id}/download.pdf`}
            download={
              materialRequestForm.purchase_order.purchase_order_pdf_document
                .attachment.filename
            }
            target="_blank"
          >
            Download Purchase Order
          </Button>
        )}

      {(loadingGeneratePurchaseOrder ||
        (!materialRequestForm?.purchase_order &&
          materialRequestForm?.create_purchase_order_job_id)) && (
        <Button variant="outlined" color="info">
          <CircularProgress
            color="success"
            size={16}
            sx={{ marginRight: "5px" }}
          />
          <span>Generating Purchase Order</span>
        </Button>
      )}

      {!loadingGeneratePurchaseOrder &&
        !materialRequestForm?.purchase_order &&
        !materialRequestForm?.create_purchase_order_job_id &&
        materialRequestForm?.filled &&
        user?.email.includes("@equinor.com") &&
        user?.roles_names.includes("Generate Purchase Order") && (
          <Button
            variant="outlined"
            href={`/vbuyer/api/v2/material_request_forms/${materialRequestFormId}/generate_purchase_order`}
            onClick={() => setLoadingGeneratePurchaseOrder(() => true)}
          >
            Generate Purchase Order
          </Button>
        )}
      {!loadingGeneratePurchaseOrder &&
        !materialRequestForm?.purchase_order &&
        !materialRequestForm?.create_purchase_order_job_id &&
        !user?.email.includes("@equinor.com") && (
          <>
            <Button variant="outlined" onClick={handleClickOpen}>
              Mass Offer
            </Button>

            <MassOfferDialog
              materialRequestFormId={materialRequestFormId}
              open={open}
              onClose={handleClose}
            />
          </>
        )}

      <br />

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Purchase Requisition</TableCell>
                <TableCell align="left">Purchase Requisition Item</TableCell>
                <TableCell align="left">Work Order</TableCell>
                <TableCell align="left">Purchase Order</TableCell>
                <TableCell align="left">Purchase Order Item</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Short Description</TableCell>
                <TableCell align="left">Unit Price</TableCell>
                <TableCell align="left">Lead Time</TableCell>
                <TableCell align="left">NCM</TableCell>
                <TableCell align="left">Tax Code</TableCell>
                <TableCell align="left">Local Content Percentage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow key={1}>
                  {Array.from(Array(12), (index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              )}
              {!loading &&
                materialRequestForm &&
                materialRequestForm.material_request_form_items?.length > 0 &&
                materialRequestForm.material_request_form_items.map(
                  (materialRequestFormItem) => (
                    <TableRow
                      key={materialRequestFormItem.id}
                      sx={{
                        borderLeftStyle: materialRequestFormItem.filled
                          ? "none"
                          : "solid",
                        borderLeftColor: blue["500"],
                      }}
                    >
                      <TableCell>
                        {materialRequestFormItem.purchase_requisition.pr_number}
                      </TableCell>
                      <TableCell>
                        {
                          materialRequestFormItem.purchase_requisition
                            .item_number
                        }
                      </TableCell>
                      <TableCell>
                        {materialRequestFormItem.purchase_requisition
                          .account_assignment_category === "F" &&
                          materialRequestFormItem.purchase_requisition
                            .plant_maintenance_order &&
                          `${materialRequestFormItem.purchase_requisition.plant_maintenance_order.order_number} - ${materialRequestFormItem.purchase_requisition.plant_maintenance_order.description}`}
                      </TableCell>
                      <TableCell>
                        {materialRequestFormItem.purchase_order_item?.po_number}
                      </TableCell>
                      <TableCell>
                        {
                          materialRequestFormItem.purchase_order_item
                            ?.po_item_number
                        }
                      </TableCell>
                      <TableCell>
                        {materialRequestFormItem.purchase_order_item
                          ?.order_quantity ||
                          materialRequestFormItem.purchase_requisition
                            .open_quantity}
                      </TableCell>
                      <TableCell>
                        {
                          materialRequestFormItem.purchase_requisition?.material
                            ?.short_description
                        }
                      </TableCell>
                      <TableCell>
                        {
                          materialRequestFormItem.purchase_requisition
                            .unit_price
                        }{" "}
                        {
                          materialRequestFormItem.purchase_requisition.currency
                            .name
                        }
                      </TableCell>
                      <TableCell>{materialRequestFormItem.lead_time}</TableCell>
                      <TableCell>{materialRequestFormItem.ncm?.code}</TableCell>
                      <TableCell>
                        {materialRequestFormItem.tax_code?.name}
                      </TableCell>
                      <TableCell>
                        {materialRequestFormItem.local_content_percentage}
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ShowMaterialRequestForm;
