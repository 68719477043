/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-console */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ChatContainer,
  ConversationHeader,
  InfoButton,
  InputToolbox,
  Message,
  MessageInput,
  MessageList,
  SendButton,
} from "@chatscope/chat-ui-kit-react";
import { MessageDirection } from "@chatscope/chat-ui-kit-react/src/types/unions";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { mdiLockOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useMutation } from "@tanstack/react-query";
import i18next from "i18next";
import {
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Vendor } from "../../../@types/services";
import IChat from "../../../@types/services/IChat";
import IChatMessage from "../../../@types/services/IChatMessage";
import useAuth from "../../../hooks/useAuth";
import createChatMessage, {
  addReadMessage,
  fetchChatMessages,
} from "../../../services/chatMessages";

interface MessageModel {
  message: string;
  sender: string;
  direction: MessageDirection;
  position: "single" | "first" | "normal" | "last" | 0 | 1 | 2 | 3;
  image: string | null;
  name: string | null;
  type: string | null;
}

interface ChatProps {
  chat: IChat;
  vendor?: Vendor;
  permissionSendMessage?: boolean;
}

interface LocalMessage {
  message: string;
  created_at: string;
}

const Chat: FC<ChatProps> = ({ chat, vendor, permissionSendMessage }) => {
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [localMessages, setLocalMessages] = useState<LocalMessage[]>([]);
  const [msgInputValue, setMsgInputValue] = useState("");
  const messageListRef = useRef<HTMLDivElement>(null);
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const { user } = useAuth();

  const mutation = useMutation({
    mutationFn: (msg: string) => createChatMessage(msg, chat.id),
  });

  const handleFetchChatMessages = useCallback(async (id: string) => {
    setLoading(true);

    await fetchChatMessages(id)
      .then((resource) => {
        setMessages(resource);
        setLocalMessages([]);
      })
      .catch(() => {
        setNotFound(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSendMessage = async (text: string) => {
    await mutation.mutate(text);
    // Atualiza as mensagens localmente
    setLocalMessages((prevMessages) => [
      ...prevMessages,
      {
        message: text,
        created_at: new Date().toISOString(),
      },
    ]);
    setMsgInputValue("");
  };

  // const handleAttachment = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     console.log("Arquivo selecionado:", file);
  //     // const newMessage: MessageModel = {
  //     //   message: "",
  //     //   sender: chat.resource_id,
  //     //   direction: "incoming",
  //     //   position: "single",
  //     //   image: URL.createObjectURL(file),
  //     //   name: file.name,
  //     //   type: file.type,
  //     // };

  //     // setMessages((prevMessages) => [...prevMessages, newMessage]);
  //     setMsgInputValue("");
  //   }
  // };

  useEffect(() => {
    handleFetchChatMessages(chat.id);
    const interval = setInterval(() => {
      handleFetchChatMessages(chat.id);
    }, 120000); // 2 minutos em milissegundos

    return () => clearInterval(interval);
  }, [chat.id, handleFetchChatMessages]);

  useEffect(() => {
    const storedMessages = localStorage.getItem(`chatMessages_${chat.id}`);
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
    addReadMessage(chat.id);
  }, [chat, chat.id]);

  useEffect(() => {
    localStorage.setItem(`chatMessages_${chat.id}`, JSON.stringify(messages));
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [chat.id, messages]);

  return (
    <ChatContainer
      style={{
        height: "400px",
        background: "background.paper",
      }}
    >
      <ConversationHeader>
        {user?.email.includes("@equinor") ? (
          <ConversationHeader.Content
            userName={`${chat.resource_type === "Vbuyer::ServiceNegotiation" && vendor ? `${vendor.fullvendorname}` : `${i18next.t("serviceNegotiation.internalChat")}`}`}
          />
        ) : (
          <ConversationHeader.Content
            userName={`${i18next.t("serviceNegotiation.chat")}`}
          />
        )}
        <ConversationHeader.Actions>
          <InfoButton />
        </ConversationHeader.Actions>
      </ConversationHeader>
      <MessageList
        ref={messageListRef}
        // typingIndicator={<TypingIndicator content="Emily is typing" />}
      >
        {messages &&
          messages.map((msg, index) => (
            <Message
              key={index}
              model={{
                message: msg.message,
                sentTime: "just now",
                sender: msg.user.first_name,
                direction: `${user?.email === msg.user.email ? "outgoing" : "incoming"}`,
                position: "single",
              }}
            >
              {/* {msg.image &&
              (!["image/png", "image/jpeg"].includes(msg.type || "") &&
              msg.name ? (
                <Message.CustomContent>
                  <Link
                    href={msg.image}
                    target="_blank"
                    underline="none"
                    display="flex"
                    alignItems="center"
                  >
                    <Icon size={0.75} path={mdiFileDocumentOutline} />
                    <Typography variant="body1">{msg.name}</Typography>
                  </Link>
                </Message.CustomContent>
              ) : (
                <Message.ImageContent
                  src={msg.image}
                  alt="Akane avatar"
                  width={200}
                />
              ))} */}
              <Message.Footer
                sender={msg.user.first_name}
                sentTime={i18next.t("dateTime", {
                  val: Date.parse(msg.created_at),
                  interpolation: { escapeValue: false },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      // hour12: false,
                    },
                  },
                })}
              />
            </Message>
          ))}
        {localMessages &&
          localMessages.map((msg, index) => (
            <Message
              key={index}
              model={{
                message: msg.message,
                sentTime: "just now",
                // sender: msg.user.first_name,
                direction: "outgoing",
                position: "single",
              }}
            >
              {/* {msg.image &&
                (!["image/png", "image/jpeg"].includes(msg.type || "") &&
                msg.name ? (
                  <Message.CustomContent>
                    <Link
                      href={msg.image}
                      target="_blank"
                      underline="none"
                      display="flex"
                      alignItems="center"
                    >
                      <Icon size={0.75} path={mdiFileDocumentOutline} />
                      <Typography variant="body1">{msg.name}</Typography>
                    </Link>
                  </Message.CustomContent>
                ) : (
                  <Message.ImageContent
                    src={msg.image}
                    alt="Akane avatar"
                    width={200}
                  />
                ))} */}
              <Message.Footer
                // sender={msg.user.first_name}
                sentTime={i18next.t("dateTime", {
                  val: Date.parse(msg.created_at),
                  interpolation: { escapeValue: false },
                  formatParams: {
                    val: {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      // hour12: false,
                    },
                  },
                })}
              />
            </Message>
          ))}
      </MessageList>
      <InputToolbox style={{ display: "flex", alignItems: "center" }}>
        <MessageInput
          onChange={(msg: SetStateAction<string>) => setMsgInputValue(msg)}
          value={msgInputValue}
          sendButton={false}
          attachButton={false}
          onSend={handleSendMessage}
          placeholder={chat.active ? "" : i18next.t("finished")}
          style={{
            flexGrow: 1,
            borderTop: 0,
            flexShrink: "initial",
          }}
          disabled={!chat.active || !permissionSendMessage}
        />
        {chat.active && permissionSendMessage ? (
          <SendButton
            onClick={() => handleSendMessage(msgInputValue)}
            disabled={msgInputValue.length === 0}
            style={{
              fontSize: "1.2em",
              marginLeft: 0,
              paddingLeft: "0.2em",
              paddingRight: "0.2em",
            }}
          />
        ) : (
          <Icon path={mdiLockOutline} size={1} style={{}} />
        )}
        {/* <AttachmentButton
          onClick={handleAttachment}
          style={{
            fontSize: "1.2em",
            paddingLeft: "0.2em",
            paddingRight: "0.2em",
          }}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        /> */}
      </InputToolbox>
    </ChatContainer>
  );
};

export default Chat;
