/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MainContainer } from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import IChat from "../../../@types/services/IChat";
import useAuth from "../../../hooks/useAuth";
import Chat from "../Chat";

interface ServiceQuotationChatProps {
  serviceQuotationChat: IChat;
}

const ServiceQuotationChat: FC<ServiceQuotationChatProps> = ({
  serviceQuotationChat,
}) => {
  const [notFound, setNotFound] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!serviceQuotationChat) {
      setNotFound(() => true);
    }
  }, [serviceQuotationChat]);

  if (notFound) {
    return <div> Chat não encontrado</div>;
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <MainContainer>
        {serviceQuotationChat && (
          <Chat
            chat={serviceQuotationChat}
            permissionSendMessage={user?.user_groups_names.includes(
              "Service Module as Buyer",
            )}
          />
        )}
      </MainContainer>
    </Box>
  );
};

export default ServiceQuotationChat;
