import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import AcceptanceTerm from "../../@types/services/AcceptanceTerm";
import Term from "../../@types/services/Term";
import TermDocument from "../../@types/services/TermDocument";
import AcceptanceTermForm from "../../components/molecules/AcceptanceTermForm";
import LoadingCentral from "../../components/molecules/LoadingCentral";
import TemplateTerms from "../../components/templates/TemplateTerms";
import useAuth from "../../hooks/useAuth";
import { fetchAcceptanceTerms } from "../../services/acceptanceTerms";
import { fetchTermDocument } from "../../services/termDocuments";
import { fetchTerms } from "../../services/terms";

interface ItemTermProps {
  term: Term;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setTerms: Dispatch<SetStateAction<Term[] | undefined>>;
}

const ItemTerm: FC<ItemTermProps> = ({ term, setLoading, setTerms }) => {
  const { user } = useAuth();
  const [termDocument, setTermDocument] = useState<TermDocument>();
  const [acceptanceTerm, setAcceptanceTerm] = useState<
    AcceptanceTerm | undefined
  >(undefined);
  const [internalLoading, setInternalLoading] = useState(false);

  const handleFetchTermDocument = async () => {
    setInternalLoading(true);
    fetchTermDocument(term.active_term_document.id)
      .then((data) => {
        setTermDocument(data);
        if (user?.vendor) {
          handleFetchAcceptanceTerm(data.id);
        }
      })
      .finally(() => {
        setInternalLoading(false);
      });
  };

  const handleFetchAcceptanceTerm = (termDocumentId: string) => {
    fetchAcceptanceTerms({
      order: "desc",
      filterTermDocument: termDocumentId,
    }).then((response) => {
      if (response.results) {
        setAcceptanceTerm(response.results[response.results.length - 1]);
      } else {
        setAcceptanceTerm(undefined);
      }
    });
  };

  useEffect(() => {
    handleFetchTermDocument();
  }, []);

  if (internalLoading || !termDocument) {
    return <LoadingCentral />;
  }

  return (
    <>
      {user?.vendor && term.mandatory && !acceptanceTerm?.accepted && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          {i18next.t("terms.alert.requiredAcceptTerm")}
        </Alert>
      )}
      <Accordion key={term.id}>
        <AccordionSummary
          expandIcon={<Icon path={mdiChevronDown} size={1} />}
          aria-controls="terms-content"
          id="terms-header"
        >
          <Typography component="span" sx={{ width: "45%", flexShrink: 0 }}>
            {term.name}
          </Typography>

          <Typography component="span" sx={{ color: "text.secondary" }}>
            {i18next.t("terms.lastUpdate")}
            {i18next.t("dateTime", {
              val: Date.parse(String(termDocument.created_at)),
              interpolation: { escapeValue: false },
              formatParams: {
                val: {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  // hour12: false,
                },
              },
            })}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box sx={{ margin: 1 }}>
            {!!user?.vendor && (
              <AcceptanceTermForm
                term={term}
                termDocument={termDocument}
                acceptanceTerm={acceptanceTerm}
                setLoading={setLoading}
                setTerms={setTerms}
              />
            )}
          </Box>
          <Divider />
          {termDocument && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <iframe
                src={
                  i18next.language === "en"
                    ? termDocument.attachment_en?.url_inline
                    : termDocument.attachment_pt_br?.url_inline
                }
                width="100%"
                height="800px"
                style={{ marginTop: "16px" }}
                title={
                  i18next.language === "en"
                    ? termDocument.attachment_en?.filename
                    : termDocument.attachment_pt_br?.filename
                }
              />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const VendorTerms: FC = (): ReactElement => {
  const [terms, setTerms] = useState<Term[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFetchTerms = () => {
    setLoading(true);
    fetchTerms()
      .then((response) => {
        setTerms(response?.results || undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleFetchTerms();
  }, []);

  return (
    <TemplateTerms>
      {loading && <LoadingCentral />}
      {terms &&
        !loading &&
        terms.map((term) => (
          <ItemTerm
            key={term.id}
            term={term}
            setTerms={setTerms}
            setLoading={setLoading}
          />
        ))}
    </TemplateTerms>
  );
};

export default VendorTerms;
