/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Conversation,
  ConversationList,
  MainContainer,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Box } from "@mui/material";
import i18next from "i18next";
import { FC, useState } from "react";
import ServiceNegotiation from "../../../@types/services/ServiceNegotiation";
import useAuth from "../../../hooks/useAuth";
import Chat from "../Chat";

interface ServiceNegotiationChatProps {
  serviceNegotiations: ServiceNegotiation[];
}

const ServiceNegotiationChats: FC<ServiceNegotiationChatProps> = ({
  serviceNegotiations,
}) => {
  const [selectedServiceNegotiation, setSelectedServiceNegotiation] =
    useState<ServiceNegotiation | null>(null);
  const { user } = useAuth();

  const handleConversationClick = (clicked: ServiceNegotiation): void => {
    setSelectedServiceNegotiation(() => clicked);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <MainContainer>
        <ConversationList
          style={{
            height: "400px",
          }}
        >
          {serviceNegotiations &&
            serviceNegotiations.map(
              (serviceNegotiation: ServiceNegotiation) => (
                <Conversation
                  key={serviceNegotiation.chat.id}
                  name={serviceNegotiation.vendor.fullvendorname} // Substitua pelo campo desejado
                  info={i18next.t("dateTime", {
                    val: Date.parse(serviceNegotiation.chat.created_at),
                    interpolation: { escapeValue: false },
                    formatParams: {
                      val: {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        // hour: "numeric",
                        // minute: "numeric",
                        // second: "numeric",
                        // hour12: false,
                      },
                    },
                  })}
                  onClick={() => handleConversationClick(serviceNegotiation)}
                />
              ),
            )}
        </ConversationList>
        {selectedServiceNegotiation && (
          <Chat
            chat={selectedServiceNegotiation.chat}
            vendor={selectedServiceNegotiation.vendor}
            permissionSendMessage={user?.user_groups_names.includes(
              "Service Module as Buyer",
            )}
          />
        )}
      </MainContainer>
    </Box>
  );
};

export default ServiceNegotiationChats;
